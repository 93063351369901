import { useProductsStore } from '~/store/productsStore.js'

const productStore = useProductsStore()

// shop top level middleware in legacy code
// use in shop and all with all shop related middlewares
export default defineNuxtRouteMiddleware(async (to, from) => {
  // const { t } = useI18n()
  if (!process.client) return
  try {
    if (to.query.diamond) {
      let diamonds = productStore.getSelectedDiamonds(to.query)

      let query = { ...to.query }

      if (!diamonds || !diamonds.length) {
        delete query.diamond
        delete query.selectedDiamond

        // TODO: add alert
        // router.app.$emit('ShowAlert', {
        //   title: t('missing-diamond-title', 'Oh... Sorry!'),
        //   message: t(
        //     'missing-diamond-message',
        //     `It seems like this diamond is no longer available. You'll find similar diamonds in our feed below.`,
        //   ),
        // })

        // let state = 'diamond'

        // if (to.query.item > 0) {
        //   state = 'item-diamond'
        // }

        // TODO: refactor and fix, related to abstract legacy router
        // return router.push({ name: state, query: query })
        // return navigateTo({ name: state, query: query })
      }
    }
  } catch (e) {
    return false
  }
})
